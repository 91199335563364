$couleur_principale : #757368;
$couleur_secondaire: #363530;
$blanc: #fff;

/* Général */
$body_background_color : $couleur_principale;
$content_background_color: $body_background_color;
$texte_lien:$couleur_secondaire;
$texte_lien_hover:lighten($couleur_secondaire, 10%);
$header_background:$couleur_principale;
$slogan_color_texte:#fff;
$titre_color:$couleur_secondaire;
$couleur_text: #fff;
$border-generales:$couleur_secondaire;

$bloc-fil-title-color:#fff;
$bloc-fil-title-hover:#fff;
$bloc-fil-title-background:$couleur_secondaire;

/* Menu */
$nav_background_color:$couleur_secondaire;
$nav_border_color:$couleur_principale;

$li_background_color:$couleur_secondaire;
$li_background_color_hover:$couleur_principale;
$nav_texte_color:#fff;
$nav_texte_color_hover:$blanc;

$sous_nav_background-color:#fff;
$nav_sous_texte_color:#333;
$nav_sous_texte_color_hover:$texte_lien;

/* Produits */
$etiquette_produit_background:$couleur_secondaire;
$etiquette_produit_texte:#fff;
$fond_produits: $couleur_secondaire;
$produits_text_color: $couleur_text;
$produits_text_color_hover: #fff;

/* Livre d'or */
$message_lo_color:$couleur_secondaire;

/* Blog / Boutons */
$btn_background_color:$couleur_secondaire;
$btn_texte_color:$blanc;
$btn_background_color_hover:#193441;
$btn_texte_color_hover:$blanc;

/* Footer */
$footer_background_color:$couleur_secondaire;
$footer_texte_color:#fff;

/* Colonne de gauche */
$colonne_gauche_background:$body_background_color;
$colonne_gauche_link_background:$couleur_secondaire;
$colonne_gauche_link_color:$blanc;
$colone_gauche_border_color:$border-generales;
$colonne_gauche_sous_link_color:$couleur_text;
$colonne_gauche_sous_link_color_hover:#fff;

/* Template Panier */
$panier-background-color:transparent;
$panier-titre-color:#fff;
$panier-content-background-color:#fff;
$panier-texte-color:#000;

/* Flux panier */
$flux-panier_color-shadow:black;

@import "design/832series/declinaison";